/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */
import React, { lazy, Suspense, useState, useEffect } from "react";
import Banner from "./components/Banner/Banner.js";
import Body from "./components/Body/Body.js"
import useData from "./components/Utils/useData.js";
import { Helmet } from "react-helmet";
import MediaQuery from "react-responsive";
import Script from "./components/widgets/widget.js";
import { CookieSetting } from "cookiesetting-component";
const FairLending = lazy(() => import("./components/FairLending/FairLending.js"));
const DisclaimerHomePage= lazy(() =>import('./components/DisclaimerHomepage/Disclaimer.js'));
const ResourcesMenu = lazy(()=>import('./components/ResourcesMenu/index.js'));
const BorrowerTestimonial = lazy(() => import("./components/VideoCarousel/VideoCarousel.js"));
 
// const Script = lazy(() => import('./components/widgets/widget.js'));
function App() {

  const [showOtherComponents, setShowOtherComponents] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.offsetHeight;
      const scrollPosition =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop;
      const maxScroll = documentHeight - windowHeight;
      const percentage = (scrollPosition / maxScroll) * 100;
  
      if (percentage > 50) {
        setShowOtherComponents(true);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const data = useData();
  if (data === null){
    return (<div />)
}
  
  return (
      <>
       <Helmet>
        <link rel="canonical" href="https://www.angelai.life/"/>
        <title>Sun West and AngelAi: Fair Lending and Low Rates for All</title>
        <meta name="description" content="AngelAi.Life" />
        <meta
          name="keywords"
          content="Sun West, AngelAi, fair lending, mortgage lending, low rates, inclusive homeownership, Ai, Chat, Bot, Assistant, Empathetic Technology, 24/7, Mortgage, Loan, Underwriter, Loan Assistant, Homebuying, Homebuyer, Home Loan, Real Estate, Real Estate Deals, Loan Originator, Loan Officer, Lender, Loan KeepingTM, Instant UnderwritingTM, Realtor®, Mortgage Technology, Blockchain Technology, TRU Approval®, The Processor, Low Rates, Mortgage Possible, Homebuyer Connect, Wholesale, Correspondent, Faster Conditions, Quick Turn Times, AngelAi, Angel Ai, Break Free, Underwriter in Your Pocket, Pocket Underwriter, Underwriter at Your Fingertips, AngelAi.com, Quick Underwriting"
        />
      </Helmet>
      <MediaQuery maxWidth={767}>
          <Banner fetchpriority="high" data= {data}/>
            <Body  />
          <Script />
       
        {showOtherComponents && (
          <>
          <Suspense fallback={<div />}>
            <ResourcesMenu />
            <FairLending data={data} />
          </Suspense>

          <Suspense fallback={<div />}>
            <BorrowerTestimonial />
            <DisclaimerHomePage data= {data}/>
          </Suspense>
         
          </>
            )}
          </MediaQuery>

          <MediaQuery minWidth={768}>
          <Banner fetchpriority="high" data= {data}/>
            <Body  />
          <Script />
          <Suspense fallback={<div />}>
            <ResourcesMenu />
          </Suspense>
         
           <Suspense fallback={<div />}>
            <FairLending data={data} />
          </Suspense>
          {showOtherComponents && (
              <>
          <Suspense fallback={<div />}>
            <BorrowerTestimonial />
            <DisclaimerHomePage data= {data}/>
          </Suspense>
          
          </>
            )}
            </MediaQuery>
            <Suspense>
            <CookieSetting
              privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK}
              configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
              analytics={process.env.REACT_APP_ANALYTICS_PATH}
            />
          </Suspense>
        </>
  );
}

export default App;
